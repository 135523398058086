import i18n from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';

// Plural Settings
// https://www.i18next.com/translation-function/plurals
// https://github.com/i18next/i18next-intervalPlural-postProcessor
// given loaded resources
// translation: {
//   key1: '{{count}} item',
//   key1_plural: '{{count}} items',
//   key1_interval: '(1){one item};(2-7){a few items};(7-inf){a lot of items};',
//   key2: '{{count}} item',
//   key2_plural: '{{count}} items',
//   key2_interval: '(1){one item};(2-7){a few items};'
// }
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Common = require('./i18n/locales/en-DEFAULT/index');
intervalPlural.setOptions({
  // these are the defaults
  intervalSeparator: ';',
  intervalRegex: /\((\S*)\).*{((.|\n)*)}/,
  intervalSuffix: '_interval',
});

i18n
  .use(intervalPlural)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: { 'en-DEFAULT': Common },
    lng: 'en-DEFAULT',
    fallbackLng: 'en-DEFAULT',
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: false,
  });

export default i18n;
