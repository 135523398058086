import * as React from 'react';
import { FC } from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { MAX_WIDTH } from '../../constants/StyleConstants';

const Container = styled(Stack)(
  ({ theme }) => `
    position: relative;
    height: 100vh;
    background-color: ${theme.colors.primary.lightness1}; 
`,
);
const Wrapper = styled(Stack)(
  () => `
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    max-width: ${MAX_WIDTH}px;
    width: 100%;
    text-align: center;
`,
);
const ErrorMessage = styled(Typography)(
  ({ theme }) => `
    font-size: 18px;
    font-weight: 400;
    margin: -30px 16px 25px;
    ${theme.breakpoints.down('sm')} {
      margin: -30px 8px 25px;
    }
`,
);

interface AccessDeniedContainerProps {}
const AccessDeniedContainer: FC<AccessDeniedContainerProps> = () => {
  return (
    <Container>
      <Wrapper>
        <ErrorMessage variant={'h1'}>
          This page is not available in your region
        </ErrorMessage>
      </Wrapper>
    </Container>
  );
};

export default AccessDeniedContainer;
