import React, { FC, useCallback, useMemo } from 'react';
import { Stack, styled, Typography, useTheme } from '@mui/material';
import { useLang } from '../../../../utils/context/LangContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouterPathName } from '../../../../containers/Router';
import { DateTime } from 'luxon';
import { UNAUTHENTICATED_EXPIRY_MINUTES } from '../../../../constants/Security';
import useSignOut from '../../../../hooks/useSignOut';
import useGetGlobalSettingLazyOperation from '../../../../graphql/operations/queries/useGetGlobalSettingLazyOperation';
import { UserCountryCodeIso1Alpha2 } from '../../../../graphql/__generated__/graphqlApi';
import formatPond from '../../../../utils/formatPond';
import Button, { PurpleButton } from '../../../atoms/buttons/Button';
import queryString from 'query-string';

const ColorBannerContainer = styled(Stack, {
  shouldForwardProp: props => props !== 'hexColorString',
})<{ hexColorString?: string | null }>`
  height: 52px;
  width: 100%;
  padding: 0 16px 0 24px;
  ${({ hexColorString }) =>
    hexColorString && `background-color: ${hexColorString}`};
  flex-direction: row;
  align-items: center;
`;
export const hasValidUnAuth = (): boolean => {
  const lastAuthTime = sessionStorage.getItem(
    'Unauthenticated_LastConfirmedTime',
  );
  if (lastAuthTime) {
    const dateTime = DateTime.fromISO(lastAuthTime);
    if (
      dateTime.isValid &&
      (UNAUTHENTICATED_EXPIRY_MINUTES == null ||
        dateTime.toMillis() >=
          DateTime.local()
            .minus({ minutes: UNAUTHENTICATED_EXPIRY_MINUTES })
            .toMillis())
    ) {
      return true;
    }
  }
  sessionStorage.removeItem('Register_Params');
  return false;
};

interface WelcomeBannerProps {
  userId?: string;
  loading?: boolean;
}
const WelcomeBanner: FC<WelcomeBannerProps> = ({ userId, loading }) => {
  const { t } = useLang();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { localizedStringsKey } = useLang();
  const outputString = localizedStringsKey.substring(
    localizedStringsKey.indexOf('_') + 1,
  );
  const { bonusConfig } = useGetGlobalSettingLazyOperation({
    country: outputString as UserCountryCodeIso1Alpha2,
  });
  const { onIdVerificationCash, onIdVerificationAmount } = bonusConfig || {};
  const handlePressToNavigate = useCallback(
    (to: RouterPathName) => () => {
      const prevNavigationString = queryString.stringify({
        prevNavigation: location.pathname,
      });
      if (to === RouterPathName.signUp) {
        const secured = hasValidUnAuth();
        const res = sessionStorage.getItem('Register_Params');
        if (!secured) {
          signOut();
        }
        navigate(
          res && secured
            ? RouterPathName.signUpRegister
            : RouterPathName.signUp,
        );
        return;
      }
      navigate({ pathname: to, search: prevNavigationString });
    },
    [location.pathname, navigate, signOut],
  );

  const amount = useMemo(
    () => onIdVerificationCash || onIdVerificationAmount,
    [onIdVerificationAmount, onIdVerificationCash],
  );

  return !loading && !userId ? (
    <Stack>
      <ColorBannerContainer hexColorString={theme.colors.graphite.lightness1}>
        <Typography
          fontWeight={'700'}
          sx={{
            flex: 1,
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            color: theme.colors.tertiary.base,
          }}
        >
          {t('welcomeBannerTitle', {
            currency: t('currency'),
            bonus: t('bonus'),
            amount: formatPond(amount ?? 0.0, true),
          })}
        </Typography>
        <PurpleButton
          height={36}
          width={80}
          onPress={handlePressToNavigate(RouterPathName.signUp)}
          style={{ margin: '0 4px' }}
          textStyle={{ fontSize: 14, lineHeight: '20px' }}
        >
          {t('signInConfirmScreenScreenTitleSignUpConfirm')}
        </PurpleButton>
        <Button
          height={36}
          width={80}
          onPress={handlePressToNavigate(RouterPathName.signIn)}
          textStyle={{ fontSize: 14, lineHeight: '20px' }}
        >
          {t('signInConfirmScreenScreenTitleSignInConfirm')}
        </Button>
      </ColorBannerContainer>
    </Stack>
  ) : (
    <></>
  );
};

export default WelcomeBanner;
