import { ApolloClient, ApolloLink } from '@apollo/client';
import { asyncMap, getOperationDefinition } from '@apollo/client/utilities';
import formatBytes from '../../utils/core/formatBytes';
import Config from 'config/getEnvConfig';
import { createCache } from './apolloCache';
import { setContext } from '@apollo/client/link/context';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { AUTH_TYPE, AuthOptions } from 'aws-appsync-auth-link';
import cognitoUserPool from '../../api/cognito/cognitoUserPool';
import * as AWS from 'aws-sdk/global';
import {
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import resolvers from './resolvers';
import {
  CheckIsValidUkPostcodeDocument,
  GetCampaignsDocument,
  GetCodeActivationEnabledDocument,
  GetCountryLanguagesDocument,
  GetForceUpgradeAppVersionConfigDocument,
  GetGlobalSettingDocument,
  GetIsReviewerPhoneNumberDocument,
  GetMatchDocument,
  GetReviewerTemporaryCodeDocument,
  GetTermAndConditionEnabledDocument,
  ListMarketsByMatchV3Document,
  OnMatchUpdatedDocument,
  SearchMatchesBySportDocument,
} from '../__generated__/graphqlApi';
import reactotron, { GraphQLLogBlacklist } from '../../ReactotronConfig';
import { getHeadersForIamAuth } from './getHeadersForIamAuth';
import { RouterPathName } from '../../containers/Router';
import { JungleAppErrorCode } from '../../utils/core/JungleAppError';

const timeStartLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: new Date().getTime() });
  return forward(operation);
});

const logTimeLink = new ApolloLink((operation, forward) => {
  // const { setIsWAFForbiddenException } = useAccessDenied();
  return asyncMap(
    forward(operation),
    async data => {
      // data from a previous link
      const time = new Date().getTime() - operation.getContext().start;
      let responseSize: string | null = null;
      try {
        if (operation.getContext().response != null) {
          responseSize = formatBytes(
            (operation.getContext().response as Response).headers.get(
              'Content-Length',
            ),
          );
        }
      } catch (e) {
        // console.error('[Apollo Client] log display error', e);
      }
      try {
        const response = await (operation.getContext().response as Response);
        if (!GraphQLLogBlacklist.includes(operation.operationName)) {
          reactotron?.display({
            name: `GraphQL ${operation.operationName}`,
            preview: operation.operationName,
            value: {
              time,
              responseSize: responseSize ?? 'null',
              variables: JSON.stringify(operation.variables, null, 1),
              headers: JSON.stringify(response?.headers),
              status: response?.status,
              data: data.data,
              errors: data.errors || [],
              query: operation.query.loc?.source.body,
            },
            important: data.errors?.length,
          });
        }
      } catch (e) {
        // console.error('[Apollo Client] reactotron display error', e);
      }
      return data;
    },
    e => {
      const errorMessage = e?.errors?.[0]?.message?.includes(
        JungleAppErrorCode.WAF_FORBIDDEN_EXCEPTION,
      );
      const accessDeniedPathname = window.location.pathname.includes(
        RouterPathName.accessDenied,
      );
      if (errorMessage && !accessDeniedPathname) {
        window.location.replace(RouterPathName.accessDenied);
      }
      return e;
    },
  );
});

const getCognitoUserSession = async (
  user: CognitoUser,
): Promise<CognitoUserSession | null> => {
  return await new Promise<CognitoUserSession | null>(res => {
    if (user == null) {
      res(null);
    } else {
      user.getSession(
        (err: Error | null, result: CognitoUserSession | null) => {
          if (err) {
            res(null);
          }
          if (!result) {
            res(null);
          }
          res(result);
        },
      );
    }
  });
};

const refreshSession = async (
  user: CognitoUser,
  credentials: AWS.Credentials,
  refreshToken: CognitoRefreshToken,
) => {
  await new Promise(res => {
    user.refreshSession(refreshToken, (err, _session) => {
      if (err) {
        console.error('refreshSession error', err);
      } else {
        credentials.refresh(err2 => {
          if (err2) {
            console.error('credentials refresh error', err2);
          } else {
            console.log('TOKEN SUCCESSFULLY UPDATED');
          }
        });
      }
      res(_session);
    });
  });
  return await new Promise<CognitoUserSession | null>(res => {
    user.getSession((err: Error | null, result: CognitoUserSession | null) => {
      if (err) {
        res(null);
      }
      if (!result) {
        res(null);
      }
      res(result);
    });
  });
};

export const getJwtToken = async (): Promise<string | undefined> => {
  // await syncUserPool();

  const user = cognitoUserPool.getCurrentUser();
  if (!user) {
    return undefined;
  }

  const session = await getCognitoUserSession(user);

  const refreshToken = session?.getRefreshToken();
  const credentials: AWS.Credentials | null =
    AWS.config.credentials instanceof AWS.Credentials
      ? AWS.config.credentials
      : null;

  if (!refreshToken || !credentials?.needsRefresh()) {
    return session?.getAccessToken().getJwtToken() ?? undefined;
  }

  return (
    (await refreshSession(user, credentials, refreshToken))
      ?.getAccessToken()
      .getJwtToken() ?? undefined
  );
};

const isNoAuthenticationRequired = (operationName: string): boolean => {
  const operationDefinitions = [
    getOperationDefinition(CheckIsValidUkPostcodeDocument)?.name?.value,
    getOperationDefinition(GetGlobalSettingDocument)?.name?.value,
    getOperationDefinition(GetForceUpgradeAppVersionConfigDocument)?.name
      ?.value,
    getOperationDefinition(GetTermAndConditionEnabledDocument)?.name?.value,
    getOperationDefinition(GetReviewerTemporaryCodeDocument)?.name?.value,
    getOperationDefinition(GetIsReviewerPhoneNumberDocument)?.name?.value,
    getOperationDefinition(GetCodeActivationEnabledDocument)?.name?.value,
    getOperationDefinition(GetCountryLanguagesDocument)?.name?.value,
    getOperationDefinition(SearchMatchesBySportDocument)?.name?.value,
    getOperationDefinition(ListMarketsByMatchV3Document)?.name?.value,
    getOperationDefinition(GetMatchDocument)?.name?.value,
    getOperationDefinition(OnMatchUpdatedDocument)?.name?.value,
    getOperationDefinition(GetCampaignsDocument)?.name?.value,
  ];

  return operationDefinitions.includes(operationName);
};

const createAuthLink = () =>
  setContext(async (operation, { headers }) => {
    const credentials = async () => {
      return {
        accessKeyId: Config.aws.appsync.iamId,
        secretAccessKey: Config.aws.appsync.iamSecret,
      };
    };
    const iamHeader = await getHeadersForIamAuth(
      {
        credentials: credentials,
        region: Config.aws.region,
        url: Config.aws.appsync.endpoint,
      },
      operation,
    );

    const apiKeyContext = {
      headers: {
        ...headers,
        ...iamHeader,
      },
    };
    if (isNoAuthenticationRequired(operation.operationName ?? '')) {
      return apiKeyContext;
    }
    const token = await getJwtToken();
    return token
      ? {
          headers: {
            ...headers,
            Authorization: token,
            'Cache-Control': 'max-age=3600',
          },
        }
      : apiKeyContext;
  });

const authOptions: AuthOptions = (() => {
  let token = '';
  getJwtToken().then(res => {
    token = res ?? '';
  });
  if (token) {
    return {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => (await getJwtToken()) ?? '',
    };
  }
  return {
    type: AUTH_TYPE.AWS_IAM,
    credentials: async () => {
      return {
        accessKeyId: Config.aws.appsync.iamId,
        secretAccessKey: Config.aws.appsync.iamSecret,
      };
    },
  };
})();

const makeApolloClient = async () => {
  const cache = await createCache(true);
  return new ApolloClient({
    link: ApolloLink.from([
      timeStartLink,
      logTimeLink,
      createAuthLink(),
      // createAuthLink({
      //   url: Config.aws.appsync.endpoint,
      //   region: Config.aws.region,
      //   auth: authOptions,
      // }),
      createSubscriptionHandshakeLink({
        url: Config.aws.appsync.endpoint,
        region: Config.aws.region,
        auth: authOptions,
      }),
    ]),
    cache,
    resolvers,
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
};

const apolloClientPromise = makeApolloClient();

export default apolloClientPromise;
