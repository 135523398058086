import React, { useCallback, useEffect, useMemo, Suspense, lazy } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
const HomeTopScreenContainer = lazy(
  () => import('./screens/home/HomeTopScreenContainer'),
);
const HomeVideoScreenContainer = lazy(
  () => import('./screens/home/HomeVideoScreenContainer'),
);
const TrialScreenContainer = lazy(
  () => import('./screens/trial/TrialScreenContainer'),
);
const TrialMatchScreenContainer = lazy(
  () => import('./screens/trialMatch/TrialMatchScreenContainer'),
);
const WidgetScreenContainer = lazy(
  () => import('./screens/widget/WidgetScreenContainer'),
);
const WidgetMatchScreenContainer = lazy(
  () => import('./screens/widgetMatch/WidgetMatchScreenContainer'),
);
// const QuizScreenContainer = lazy(
//   () => import('./screens/quiz/QuizScreenContainer'),
// );
const LatestScreenContainer = lazy(
  () => import('./screens/latest/LatestScreenContainer'),
);
const LiveAudioPlayerScreenContainer = lazy(
  () => import('./screens/listen/LiveAudioPlayerScreenContainer'),
);
const Layout = lazy(() => import('../components/molecules/layout/Layout'));
const SignUpScreenContainer = lazy(
  () => import('./screens/auth/SignUpScreenContainer'),
);
const SignInScreenContainer = lazy(
  () => import('./screens/auth/SignInScreenContainer'),
);
const SignInConfirmScreenContainer = lazy(
  () => import('./screens/auth/SignInConfirmScreenContainer'),
);
const SignUpRegisterScreenContainer = lazy(
  () => import('./screens/auth/SignUpRegisterScreenContainer'),
);
const WalletDepositHistoryScreenContainer = lazy(
  () => import('./screens/account/WalletDepositHistoryScreenContainer'),
);
const PaymentLayout = lazy(
  () => import('../components/molecules/layout/PaymentLayout'),
);
const WalletWithdrawScreenContainer = lazy(
  () => import('./screens/account/WalletWithdrawScreenContainer'),
);
const WalletDepositScreenContainer = lazy(
  () => import('./screens/account/WalletDepositScreenContainer'),
);
const WalletEveryMatrixCashierScreenContainer = lazy(
  () => import('./screens/account/WalletEveryMatrixCashierScreenContainer'),
);
const WalletTopScreenContainer = lazy(
  () => import('./screens/account/WalletTopScreenContainer'),
);
const BetTransactionListScreenContainer = lazy(
  () => import('./screens/BetTransactionListScreenContainer'),
);
const AccountTopScreenContainer = lazy(
  () => import('./screens/AccountTopScreenContainer'),
);
const EditAccountScreenContainer = lazy(
  () => import('./screens/account/EditAccountScreenContainer'),
);
const GamblingControlsTopScreenContainer = lazy(
  () => import('./screens/settings/GamblingControlsTopScreenContainer'),
);
const SettingsLimitationsScreenContainer = lazy(
  () => import('./screens/settings/SettingsLimitationsScreenContainer'),
);
const SettingsTimeOutScreenContainer = lazy(
  () => import('./screens/settings/SettingsTimeOutScreenContainer'),
);
const SettingsSelfExclusionScreenContainer = lazy(
  () => import('./screens/settings/SettingsSelfExclusionScreenContainer'),
);
const PublicLayout = lazy(
  () => import('components/molecules/layout/PublicLayout'),
);
const VerifyIdScreenContainer = lazy(
  () => import('./screens/launch/VerifyIdScreenContainer'),
);
const AccountSettingScreenContainer = lazy(
  () => import('./screens/settings/AccountSettingScreenContainer'),
);
const SettingCloseAccountContainer = lazy(
  () => import('./screens/settings/SettingCloseAccountContainer'),
);
const OnboardingFlowContainer = lazy(
  () => import('./screens/launch/OnboardingFlowContainer'),
);
import useSubscribeVerificationStatusUpdate from '../graphql/operations/subscriptions/useSubscribeVerificationStatusUpdate';
import {
  ApplicableLocalizedStrings,
  useLang,
} from '../utils/context/LangContext';
import { I18NextLanguageCode, LangType, LanguageCode } from 'types/lang';
import {
  GetMyVerificationStatusV2Document,
  IdVerificationStatus,
  useGetMyVerificationStatusV2Query,
  UserCountryCodeIso1Alpha2,
} from '../graphql/__generated__/graphqlApi';
import { useSetUserId } from '../hooks/useSetUserId';
import useSelectMeProp from 'hooks/selector/useSelectMeProp';
const SignInReauthScreenContainer = lazy(
  () => import('./screens/auth/SignInReauthScreenContainer'),
);
import AcceptVersionModal from '../components/organisms/modal/AcceptVersionModal';
import {
  StatusLogin,
  useModalContext,
} from '../providers/ModalContextProvider';
import useOpenLink from '../hooks/useOpenLink';
import useAcceptTermAndConditionOperation from 'graphql/operations/mutations/useAcceptTermAndConditionOperation';
const PageNotFoundContainer = lazy(
  () => import('./screens/PageNotFoundContainer'),
);
import useSignOut from '../hooks/useSignOut';
import { hasValidUnAuth } from '../components/molecules/profile/top/WelcomeBanner';
const ArticleContainer = lazy(() => import('./screens/ArticleContainer'));
const PreviewContainer = lazy(() => import('./screens/PreviewContainer'));
import useGetGlobalSettingOperation from '../graphql/operations/queries/useGetGlobalSettingOperation';
import { COOKIE_KEY } from '../constants/Cookie';
import AsyncStorageManager from '../services/AsyncStorageManager';
import { useIntercom } from 'react-use-intercom';
import queryString from 'query-string';
import { useApolloClient } from '@apollo/client';
import AccessDeniedContainer from './screens/AccessDeniedContainer';
const GlobalLayout = lazy(
  () => import('../components/molecules/layout/GlobalLayout'),
);

export enum RouterPathName {
  match = '/match',
  signUp = '/sign-up',
  signUpRegister = '/sign-up-register',
  signIn = '/sign-in',
  signInReauth = '/sign-in-reauth',
  home = '/',
  balanceTop = '/balance-top',
  verifyId = '/verify-id',
  accountTop = '/account-top',
  editAccount = '/edit-account',
  gamblingControlsTop = '/gambling-controls-top',
  gamblingControlsLimitations = '/gambling-controls-limitations',
  gamblingControlsTimeOut = '/gambling-controls-time-out',
  gamblingControlsSelfExecution = '/gambling-controls-self-execution',
  accountSettingsTop = '/account-settings-top',
  closeAccount = '/close-account',
  onBoarding = '/onboarding',
  confirm = '/confirm',
  paymentDeposit = '/payment-deposit',
  paymentWithdraw = '/payment-withdraw',
  depositHistory = '/deposit-history',
  paymentEveryMatrixCashier = '/payment-every-matrix-cashier',
  betTransactionList = '/bet-transaction-list',
  developerOptions = '/developer-options',
  article = '/article/:id',
  draft = '/draft',
  widget = '/widget',
  widgetMatch = '/widget/match',
  quiz = '/quiz',
  latest = '/:id/latest',
  trial = '/trial',
  trialMatch = '/trial/match',
  trial2 = '/trial2',
  trial2Match = '/trial2/match',
  video = '/video',
  accessDenied = '/access-denied',
}

function Router() {
  useSetUserId();
  const { trackEvent } = useIntercom();
  const userId = useSelectMeProp('id');
  const country = useSelectMeProp('country');
  const signOut = useSignOut();
  useSubscribeVerificationStatusUpdate(userId);
  const { myCountryCode } = useGetGlobalSettingOperation();
  const navigate = useNavigate();
  const cache = useApolloClient().cache;

  const { changeLanguage } = useLang();

  const {
    isTermAndConditionVisible,
    setIsTermAndConditionVisible,
    termAndCondition,
    setStatusLogin,
  } = useModalContext();

  const pollInterval = 5000;
  const {
    data: verificationStatusData,
    startPolling,
    stopPolling,
  } = useGetMyVerificationStatusV2Query({
    fetchPolicy: 'no-cache',
    skip: !userId,
    pollInterval,
  });

  useEffect(() => {
    startPolling(pollInterval);
    return () => {
      stopPolling();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const verifyStatusStorage = await AsyncStorageManager.getItem(
        'Verification',
      );
      const isComplete =
        verificationStatusData?.myIdVerificationStatusV2?.status ===
        IdVerificationStatus.Complete;
      const isRejected =
        verificationStatusData?.myIdVerificationStatusV2?.status ===
        IdVerificationStatus.Rejected;
      if (
        !verificationStatusData?.myIdVerificationStatusV2?.status ||
        (verifyStatusStorage === IdVerificationStatus.Complete && isComplete) ||
        (verifyStatusStorage === IdVerificationStatus.Rejected && isRejected)
      ) {
        return;
      }
      if (isComplete || isRejected) {
        trackEvent('Verification', {
          verification:
            verificationStatusData?.myIdVerificationStatusV2?.status,
          document:
            verificationStatusData.myIdVerificationStatusV2.reportStatus
              ?.document,
          facialSimilarityMotion:
            verificationStatusData.myIdVerificationStatusV2.reportStatus?.face,
          proofOfAddress:
            verificationStatusData.myIdVerificationStatusV2.reportStatus
              ?.address,
          aml: verificationStatusData.myIdVerificationStatusV2.reportStatus
            ?.aml,
        });
        await AsyncStorageManager.setItem(
          'Verification',
          verificationStatusData?.myIdVerificationStatusV2?.status ?? '',
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    verificationStatusData?.myIdVerificationStatusV2?.status,
    verificationStatusData?.myIdVerificationStatusV2?.reportStatus,
  ]);

  useEffect(() => {
    function trigger(event: MessageEvent) {
      if (event.data?.type === 'limitation') {
        cache.updateQuery(
          { query: GetMyVerificationStatusV2Document },
          data => ({
            myIdVerificationStatusV2: {
              ...data.myIdVerificationStatusV2,
              status: IdVerificationStatus.Waiting,
            },
          }),
        );
        const params = {
          step: 'setupLimitation',
        };
        const searchString = queryString.stringify(params);
        navigate({
          pathname: RouterPathName.onBoarding,
          search: searchString,
        });
        return;
      }

      if (event.data?.type === 'verify') {
        cache.updateQuery(
          { query: GetMyVerificationStatusV2Document },
          data => ({
            myIdVerificationStatusV2: {
              ...data.myIdVerificationStatusV2,
              status: IdVerificationStatus.Waiting,
            },
          }),
        );
        window.location.replace(RouterPathName.home);
      }
    }

    if (!window.location.search.includes('redirectFrom=')) {
      window.addEventListener('message', trigger, false);
    }

    if (window.location.search.includes('redirectFrom=limitation')) {
      window.parent.postMessage(
        {
          type: 'limitation',
        },
        '*',
      );
    }

    if (window.location.search.includes('redirectFrom=verify')) {
      window.parent.postMessage(
        {
          type: 'verify',
        },
        '*',
      );
    }

    return () => {
      window.removeEventListener('message', trigger);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRegistering = useMemo(() => {
    const securedUnAuth = hasValidUnAuth();
    return !!(securedUnAuth && sessionStorage.getItem('Register_Params'));
  }, []);

  const onBoarding = useCallback(() => {
    const res = localStorage.getItem(COOKIE_KEY.ONBOARDING_FIRSTAPPSTART);
    const redirectArray = [
      RouterPathName.signUpRegister,
      RouterPathName.signInReauth,
      RouterPathName.confirm,
      RouterPathName.onBoarding,
      RouterPathName.verifyId,
      RouterPathName.paymentEveryMatrixCashier,
      RouterPathName.betTransactionList,
      RouterPathName.editAccount,
      RouterPathName.closeAccount,
      RouterPathName.gamblingControlsTop,
      RouterPathName.gamblingControlsLimitations,
      RouterPathName.gamblingControlsTimeOut,
      RouterPathName.gamblingControlsSelfExecution,
    ];
    if (
      !res &&
      redirectArray.includes(window.location.pathname as RouterPathName)
    ) {
      navigate(RouterPathName.signIn);
    }
  }, [navigate]);

  useEffect(() => {
    onBoarding();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNotLogin = useMemo(
    () =>
      window.location.pathname.includes(RouterPathName.onBoarding) ||
      window.location.pathname.includes(
        RouterPathName.gamblingControlsLimitations,
      ) ||
      window.location.pathname.includes(RouterPathName.signUpRegister) ||
      window.location.pathname.includes(RouterPathName.confirm) ||
      window.location.pathname.includes(RouterPathName.signInReauth),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.pathname],
  );

  useEffect(() => {
    if (!userId && isRegistering) {
      signOut(true);
      return;
    }
    if (userId && !isNotLogin) {
      setStatusLogin(StatusLogin.Logged);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, isNotLogin, isRegistering]);

  useEffect(() => {
    if (!country) {
      handleChangeLanguage(myCountryCode as UserCountryCodeIso1Alpha2);
      return;
    }
    handleChangeLanguage(country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, myCountryCode]);

  const handleChangeLanguage = useCallback(
    (country: UserCountryCodeIso1Alpha2 | null) => {
      if (country === UserCountryCodeIso1Alpha2.Ie) {
        changeLanguage({
          lang: LangType.en_IE,
          localizedStringsKey:
            LangType.en_IE as keyof ApplicableLocalizedStrings,
          languageCode: LanguageCode.en,
          languageAreaCode: LangType.en_IE,
          i18NextLanguageCode: I18NextLanguageCode.en_IE,
        });
        return;
      }
      if (country === UserCountryCodeIso1Alpha2.Gb) {
        changeLanguage({
          lang: LangType.en_GB,
          localizedStringsKey: LangType.en_GB,
          languageCode: LanguageCode.en,
          languageAreaCode: LangType.en_GB,
          i18NextLanguageCode: I18NextLanguageCode.en_GB,
        });
        return;
      }
      if (country === UserCountryCodeIso1Alpha2.Jp) {
        changeLanguage({
          lang: LangType.ja_JP,
          localizedStringsKey:
            LangType.ja_JP as keyof ApplicableLocalizedStrings,
          languageCode: LanguageCode.ja,
          languageAreaCode: LangType.ja_JP,
          i18NextLanguageCode: I18NextLanguageCode.ja_JP,
        });
        return;
      }
      changeLanguage({
        lang: LangType.en_DEFAULT,
        localizedStringsKey: LangType.en_GB,
        languageCode: LanguageCode.en,
        languageAreaCode: LangType.en_DEFAULT,
        i18NextLanguageCode: I18NextLanguageCode.en_DEFAULT,
      });
    },
    [changeLanguage],
  );
  const handleAcceptTermAndConditionOperation =
    useAcceptTermAndConditionOperation();
  const { openLink } = useOpenLink();
  const handleTapAccountNumber = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      openLink(e, termAndCondition?.link ?? '');
    },
    [openLink, termAndCondition?.link],
  );
  const handleAccept = useCallback(async () => {
    if (!termAndCondition) return;
    await handleAcceptTermAndConditionOperation(termAndCondition.id);
    setIsTermAndConditionVisible(false);
  }, [
    handleAcceptTermAndConditionOperation,
    setIsTermAndConditionVisible,
    termAndCondition,
  ]);

  const isSignInAgain = useMemo(
    () => window.location.pathname.includes(RouterPathName.signInReauth),
    [],
  );

  return (
    <>
      <AcceptVersionModal
        isVisible={isTermAndConditionVisible && !isSignInAgain}
        title={termAndCondition?.title}
        message={termAndCondition?.message}
        onReview={handleTapAccountNumber}
        onAccept={handleAccept}
      />
      <Routes>
        <Route
          path={RouterPathName.widgetMatch}
          element={
            <Suspense fallback={<div />}>
              <WidgetMatchScreenContainer />
            </Suspense>
          }
        />
        <Route
          path={RouterPathName.latest}
          element={
            <Suspense fallback={<div />}>
              <LatestScreenContainer />
            </Suspense>
          }
        />
        <Route
          path={RouterPathName.video}
          element={
            <Suspense fallback={<div />}>
              <HomeVideoScreenContainer />
            </Suspense>
          }
        />
        <Route
          element={
            <Suspense fallback={<div />}>
              <GlobalLayout userId={userId} />
            </Suspense>
          }
        >
          <Route
            path={RouterPathName.article}
            element={
              <Suspense fallback={<div />}>
                <ArticleContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.draft}
            element={
              <Suspense fallback={<div />}>
                <PreviewContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.widget}
            element={
              <Suspense fallback={<div />}>
                <WidgetScreenContainer />
              </Suspense>
            }
          />
          {/* <Route*/}
          {/*  path={RouterPathName.quiz}*/}
          {/*  element={*/}
          {/*    <Suspense fallback={<div />}>*/}
          {/*      <QuizScreenContainer />*/}
          {/*    </Suspense>*/}
          {/*  }*/}
          {/* />*/}
          <Route
            path={RouterPathName.accessDenied}
            element={
              <Suspense fallback={<div />}>
                <AccessDeniedContainer />
              </Suspense>
            }
          />
          <Route
            path='*'
            element={
              <Suspense fallback={<div />}>
                <PageNotFoundContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.accessDenied}
            element={
              <Suspense fallback={<div />}>
                <AccessDeniedContainer />
              </Suspense>
            }
          />
        </Route>
        <Route
          path={RouterPathName.home}
          element={
            <Suspense fallback={<div />}>
              <PublicLayout />
            </Suspense>
          }
        >
          <Route
            path={RouterPathName.signUp}
            element={
              <Suspense fallback={<div />}>
                <SignUpScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.signIn}
            element={
              <Suspense fallback={<div />}>
                <SignInScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.signInReauth}
            element={
              <Suspense fallback={<div />}>
                <SignInReauthScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.confirm}
            element={
              <Suspense fallback={<div />}>
                <SignInConfirmScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.signUpRegister}
            element={
              <Suspense fallback={<div />}>
                <SignUpRegisterScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.onBoarding}
            element={
              <Suspense fallback={<div />}>
                <OnboardingFlowContainer />
              </Suspense>
            }
          />
        </Route>

        <Route
          path={RouterPathName.home}
          element={
            <Suspense fallback={<div />}>
              <Layout />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<div />}>
                <HomeTopScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.trial}
            element={
              <Suspense fallback={<div />}>
                <TrialScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.trial2}
            element={
              <Suspense fallback={<div />}>
                <TrialScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.trialMatch}
            element={
              <Suspense fallback={<div />}>
                <TrialMatchScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.trial2Match}
            element={
              <Suspense fallback={<div />}>
                <TrialMatchScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.match}
            element={
              <Suspense fallback={<div />}>
                <LiveAudioPlayerScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.balanceTop}
            element={
              <Suspense fallback={<div />}>
                <WalletTopScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.verifyId}
            element={
              <Suspense fallback={<div />}>
                <VerifyIdScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.accountTop}
            element={
              <Suspense fallback={<div />}>
                <AccountTopScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.gamblingControlsTop}
            element={
              <Suspense fallback={<div />}>
                <GamblingControlsTopScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.accountSettingsTop}
            element={
              <Suspense fallback={<div />}>
                <AccountSettingScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.closeAccount}
            element={
              <Suspense fallback={<div />}>
                <SettingCloseAccountContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.editAccount}
            element={
              <Suspense fallback={<div />}>
                <EditAccountScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.gamblingControlsLimitations}
            element={
              <Suspense fallback={<div />}>
                <SettingsLimitationsScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.gamblingControlsTimeOut}
            element={
              <Suspense fallback={<div />}>
                <SettingsTimeOutScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.gamblingControlsSelfExecution}
            element={
              <Suspense fallback={<div />}>
                <SettingsSelfExclusionScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.betTransactionList}
            element={
              <Suspense fallback={<div />}>
                <BetTransactionListScreenContainer />
              </Suspense>
            }
          />
        </Route>
        <Route
          element={
            <Suspense fallback={<div />}>
              <PaymentLayout />
            </Suspense>
          }
        >
          <Route
            path={RouterPathName.depositHistory}
            element={
              <Suspense fallback={<div />}>
                <WalletDepositHistoryScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.paymentWithdraw}
            element={
              <Suspense fallback={<div />}>
                <WalletWithdrawScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.paymentDeposit}
            element={
              <Suspense fallback={<div />}>
                <WalletDepositScreenContainer />
              </Suspense>
            }
          />
          <Route
            path={RouterPathName.paymentEveryMatrixCashier}
            element={
              <Suspense fallback={<div />}>
                <WalletEveryMatrixCashierScreenContainer />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default Router;
