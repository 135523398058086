import { ThemeOptions } from '@mui/material';
import { ThemeColors } from '../types/theme';

function getGrayScaleColor(color?: string) {
  if (!color) {
    return '#ABABAB';
  }
  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color)) {
    return color;
  }
  let r, g, b;
  if (color.length === 4) {
    r = parseInt(color[1] + color[1], 16);
    g = parseInt(color[2] + color[2], 16);
    b = parseInt(color[3] + color[3], 16);
  } else {
    r = parseInt(color.slice(1, 3), 16);
    g = parseInt(color.slice(3, 5), 16);
    b = parseInt(color.slice(5, 7), 16);
  }

  if (r === 255 && g === 255 && b === 255) {
    return '#ABABAB';
  }

  const grayScale = Math.round(0.299 * r + 0.587 * g + 0.114 * b);

  return '#' + grayScale.toString(16).repeat(3);
}

export const MONOCHROME = '#ABABAB';
export const COLOR_BASE = '#008DFF';

export const colors: ThemeColors = {
  primary: {
    base: COLOR_BASE,
    lightness1: '#07162F',
  },
  secondary: {
    base: '#fff',
  },
  tertiary: {
    base: '#07162F',
    lightness1: '#002A4C',
    lightness2: '#042D4A',
    lightness3: '#002A4C',
    lightness4: '#1C3154',
    darkness1: '#1F2D43',
    darkness2: '#2C394E',
    darkness3: '#07162F1A',
    darkness4: '#07162F66',
    darkness5: '#07162F80',
  },
  danger: {
    base: '#fff',
  },
  gray: {
    base: '#838A97',
    lightness1: '#B4B9C0',
    lightness2: '#E6E7EA',
    lightness3: '#D8D8D8',
    lightness4: '#E0E0E0',
    lightness5: '#F2F3F4',
    darkness1: '#8c8c8c',
    darkness2: '#FFFFFF80',
    darkness3: '#E1E7EC',
    darkness4: '#697280',
    darkness5: '#544E4E',
  },
  blue: {
    base: '#5D5FEF',
    lightness1: '#80C6FF',
    lightness2: '#3C5A99',
    lightness3: '#1DA1F2',
    lightness4: '#C2E0FF',
    lightness5: '#F0F7FF',
    darkness1: '#003A75',
  },
  yellow: {
    base: '#B0955F',
    lightness1: '#B0955F',
  },
  green: {
    base: '#41AB6D',
    lightness1: '#27AE60',
    lightness2: '#D2F7E2',
  },
  white: {
    base: '#FFFFFF',
    lightness1: '#d9d9d9',
    lightness2: '#edf1f4',
    lightness4: '#AEB7C7',
    lightness5: '#F8F8FA',
    darkness1: '#DDDDDD',
    darkness2: '#ffffff4d',
    darkness3: '#FFFFFF80',
    darkness4: '#FFFFFF40',
  },
  black: {
    base: '#000000',
    lightness1: '#676767',
    darkness1: '#242424',
  },
  pink: {
    base: '#fff',
  },
  purple: {
    base: '#7571FF',
    lightness1: '#735CE9',
    lightness2: '#5A46B6',
    darkness1: '#3D31E7',
  },
  red: {
    base: '#F84646',
    lightness1: '#FE493A',
    lightness2: '#F85A46',
    lightness3: '#FFEDEC',
  },
  lime: {
    base: '#D3FF00',
    lightness1: '#7ED321',
    lightness2: '#D3FF0080',
  },
  orange: {
    base: '#f17330',
  },
  graphite: {
    base: '#9C9DAF',
    lightness1: '#D4DBE4',
  },
};

export const monochromeColors: ThemeColors = {
  primary: {
    ...colors.primary,
    base: MONOCHROME,
  },
  secondary: {
    ...colors.secondary,
  },
  tertiary: {
    ...colors.tertiary,
    darkness3: getGrayScaleColor(colors.tertiary.darkness3),
  },
  danger: {
    ...colors.danger,
  },
  gray: {
    base: getGrayScaleColor(colors.gray.base),
    lightness1: getGrayScaleColor(colors.gray.lightness1),
    lightness2: getGrayScaleColor(colors.gray.lightness2),
    lightness3: getGrayScaleColor(colors.gray.lightness3),
    lightness4: getGrayScaleColor(colors.gray.lightness3),
    lightness5: getGrayScaleColor(colors.gray.lightness5),
    darkness1: colors.gray.darkness1,
    darkness2: getGrayScaleColor(colors.gray.darkness2),
    darkness3: getGrayScaleColor(colors.gray.darkness3),
    darkness4: getGrayScaleColor(colors.gray.darkness4),
    darkness5: colors.gray.darkness5,
  },
  blue: {
    ...colors.blue,
    base: getGrayScaleColor(colors.blue.base),
    lightness2: getGrayScaleColor(colors.blue.lightness2),
    lightness3: getGrayScaleColor(colors.blue.lightness3),
  },
  yellow: {
    base: getGrayScaleColor(colors.yellow.base),
    lightness1: getGrayScaleColor(colors.yellow.lightness1),
  },
  green: {
    base: getGrayScaleColor(colors.green.base),
    lightness1: getGrayScaleColor(colors.green.lightness1),
  },
  white: {
    ...colors.white,
    base: getGrayScaleColor(colors.white.base),
    lightness1: getGrayScaleColor(colors.white.lightness1),
    lightness2: getGrayScaleColor(colors.white.lightness2),
    lightness4: getGrayScaleColor(colors.white.lightness4),
    darkness3: getGrayScaleColor(colors.white.darkness3),
  },
  black: {
    base: getGrayScaleColor(colors.black.base),
  },
  pink: {
    ...colors.pink,
  },
  purple: {
    ...colors.purple,
    base: getGrayScaleColor(colors.purple.base),
    lightness1: getGrayScaleColor(colors.purple.lightness1),
    darkness1: getGrayScaleColor(colors.purple.darkness1),
  },
  red: {
    base: getGrayScaleColor(colors.red.base),
    lightness1: getGrayScaleColor(colors.red.lightness1),
    lightness2: getGrayScaleColor(colors.red.lightness2),
  },
  lime: {
    base: getGrayScaleColor(colors.lime.base),
    lightness1: getGrayScaleColor(colors.lime.lightness1),
    lightness2: getGrayScaleColor(colors.lime.lightness2),
  },
  orange: {
    base: getGrayScaleColor(colors.orange.base),
  },
  graphite: {
    base: getGrayScaleColor(colors.graphite.base),
    lightness1: getGrayScaleColor(colors.graphite.lightness1),
  },
};

export const themeOptions: ThemeOptions = {
  typography: {
    allVariants: {
      color: colors.white.base,
      fontFamily: 'Montserrat',
    },
    h1: {
      fontSize: 32,
      lineHeight: '40px',
    },
    h2: {
      fontSize: 28,
      lineHeight: '34px',
    },
    h3: {
      fontSize: 24,
      lineHeight: '28px',
    },
    h4: {
      fontSize: 22,
      lineHeight: '26px',
    },
    h5: {
      fontSize: 20,
      lineHeight: '24px',
    },
    h6: {
      fontSize: 18,
      lineHeight: '22px',
    },
    body1: {
      fontSize: 14,
      lineHeight: '18px',
    },
    body2: {
      fontSize: 12,
      lineHeight: '16px',
    },
    caption: {
      fontSize: 10,
      lineHeight: '12px',
    },
    button: {
      fontSize: 16,
      lineHeight: '20px',
    },
  },
};

export const getDesignTokens = (monochrome: boolean) => {
  if (monochrome) {
    return monochromeColors;
  }
  return colors;
};
